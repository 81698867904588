// /*
//  * Headroom
//  *
//  * Calling the Headroom.js object
//  */
// /* eslint-disable */
// let Headroom = require('headroom.js');
//
// function header() {
//     // Grab an element
//     let header = document.querySelector('#header');
//
//     // Construct an instance of Headroom, passing the element
//     let headroom = new Headroom(header, {
//         offset: 40,
//         tolerance: {
//             up: 15,
//             down: 25,
//         },
//         classes: {
//             // when element is initialised
//             initial: 'l-headroom',
//             // when scrolling up
//             pinned: 'l-headroom--pinned',
//             // when scrolling down
//             unpinned: 'l-headroom--unpinned',
//             // when above offset
//             top: 'l-headroom--top',
//             // when below offset
//             notTop: 'l-headroom--not-top',
//             // when at bottom of scoll area
//             bottom: 'l-headroom--bottom',
//             // when not at bottom of scroll area
//             notBottom: 'l-headroom--not-bottom',
//         },
//     });
//
//     // Initialise
//     headroom.init();
//
//     // Check if the body element has the "l-body--with-banner" class. This comes from the site options
//     if ($('body').hasClass('l-body--with-banner')) {
//         // Check if the banner_closed cookie is not set
//         if (
//             !document.cookie
//                 .split(';')
//                 .some((item) => item.trim().startsWith('banner_closed='))
//         ) {
//             // If the cookie is not set, remove the "hidden" class and slide down the banner to show it
//             $('.o-header-1__header-top-banner-wrapper')
//                 .removeClass('hidden')
//                 .slideDown();
//             // Add the "l-body--with-banner" class to the .l-body element
//             $('.l-body').addClass('l-body--with-banner');
//         } else {
//             $('.l-body').removeClass('l-body--with-banner');
//         }
//
//         $('.o-header-top-banner__close-btn').on('click', function (e) {
//             e.preventDefault();
//             $('.o-header-1__header-top-banner-wrapper').slideUp();
//             // Remove the "l-body--with-banner" class from the .l-body element
//             $('.l-body').removeClass('l-body--with-banner');
//             // Set a cookie to prevent banner from reopening for 2 weeks
//             document.cookie =
//                 'banner_closed=true; max-age=' + 60 * 60 * 24 * 14 + '; path=/';
//         });
//     }
// }
//
// export default header;

/*
 * @file Header.js
 * @description Manages the header behavior using Headroom.js, ensuring smooth scrolling and dynamic updates.
 * @author Dreamers of Day
 */

/* eslint-disable no-console */

// Import the Headroom.js library
const Headroom = require('headroom.js');

/**
 * Initializes and manages header behavior, including dynamic transformations
 * and maintaining compatibility with Headroom.js.
 */
function header() {
    // Select the required DOM elements
    const headerElement = document.querySelector('#header');
    const slideOutElement = document.querySelector('.js-slide-out');
    const bannerWrapperElement = document.querySelector(
        '.o-header-1__header-top-banner-wrapper',
    );

    // Ensure all necessary elements are present in the DOM
    if (!headerElement || !slideOutElement || !bannerWrapperElement) {
        console.warn('Required elements not found in the DOM.');
        return;
    }

    /**
     * Initializes the Headroom.js instance for header scrolling behavior.
     */
    const headroom = new Headroom(headerElement, {
        offset: 40,
        tolerance: { up: 15, down: 25 },
        classes: {
            initial: 'l-headroom',
            pinned: 'l-headroom--pinned',
            unpinned: 'l-headroom--unpinned',
            top: 'l-headroom--top',
            notTop: 'l-headroom--not-top',
            bottom: 'l-headroom--bottom',
            notBottom: 'l-headroom--not-bottom',
        },
    });
    headroom.init();

    /**
     * Updates the padding-top of the slide-out element based on header height.
     * Dynamically calculates if the header is translated upward by the banner height.
     */
    const updatePaddingTop = () => {
        const headerHeight = headerElement.offsetHeight;
        const bannerHeight = bannerWrapperElement.offsetHeight;

        // Calculate the actual header position
        const headerRect = headerElement.getBoundingClientRect();
        const isTranslated = Math.abs(headerRect.top) >= bannerHeight;

        // Adjust the padding based on translation
        slideOutElement.style.paddingTop = isTranslated
            ? `${headerHeight - bannerHeight}px`
            : `${headerHeight}px`;
    };

    // Perform initial padding adjustment
    updatePaddingTop();

    // Adjust padding on window resize
    window.addEventListener('resize', updatePaddingTop);

    /**
     * Observes changes in the header's DOM structure to adjust the padding-top dynamically.
     */
    const mutationObserver = new MutationObserver(updatePaddingTop);
    mutationObserver.observe(headerElement, {
        attributes: true,
        childList: true,
        subtree: true,
    });

    // Disconnect the observer before unloading the page
    window.addEventListener('beforeunload', () => {
        mutationObserver.disconnect();
    });

    /**
     * Updates the header's transformation based on its current state.
     */
    const updateHeaderTransform = () => {
        const bannerHeight = bannerWrapperElement.offsetHeight;

        if (headerElement.classList.contains('l-headroom--unpinned')) {
            headerElement.style.transform = `translateY(-${bannerHeight}px)`;
        } else {
            headerElement.style.transform = ''; // Reset transformation
        }
    };

    // Observe class changes on the header element to update transformations
    const classObserver = new MutationObserver(updateHeaderTransform);
    classObserver.observe(headerElement, {
        attributes: true,
        attributeFilter: ['class'],
    });

    // Update transformations on window resize
    window.addEventListener('resize', updateHeaderTransform);

    // Perform initial transform update
    updateHeaderTransform();

    /**
     * Checks and manages the banner's visibility based on cookies.
     */
    const manageBannerVisibility = () => {
        const isBannerClosed = document.cookie
            .split(';')
            .some((cookie) => cookie.trim().startsWith('banner_closed='));

        if (!isBannerClosed) {
            $('.o-header-1__header-top-banner-wrapper')
                .removeClass('hidden')
                .slideDown();
        }

        $('.o-header-top-banner__close-btn').on('click', (event) => {
            event.preventDefault();
            $('.o-header-1__header-top-banner-wrapper').slideUp();

            // Set a cookie to hide the banner for 14 days
            document.cookie = `banner_closed=true; max-age=${
                60 * 60 * 24 * 14
            }; path=/`;
        });
    };

    // Initialize banner visibility management
    manageBannerVisibility();
}

export default header;
